import "./main.scss"

import { FilterView } from "./js/components/filter-view"
import { ArticlesView } from "./js/components/articles-view"
import { NavbarEffects } from "./js/components/navbar"
import { Gallery } from "./js/components/image-slider"
import { PhaseCircles } from "./js/components/phase-circles"
import { Lightboxes } from "./js/components/lightboxes"
import { ScrollToTopButton } from "./js/components/scroll-to-top-button"
import { ViewportFadeObserver } from "./js/utils/viewport-fade-observer"

import { qs, qsa } from "./js/utils/dom"
import MicroModal from "micromodal"

window.onload = () => {
  new ViewportFadeObserver()
  MicroModal.init()
}

document.addEventListener(
  "DOMContentLoaded",
  () => {
    // Navbar scolling and mobile toggle effects
    const navbar = qs(".nav-container")
    if (navbar) new NavbarEffects(navbar)

    // filter logic and card lists
    const projectsView = qs("[data-projects-view]")
    if (projectsView) new FilterView(projectsView, "project")

    const focView = qs("[data-foc-view]")
    if (focView) new FilterView(focView, "foc")

    const jobsView = qs("[data-jobs-view]")
    if (jobsView) new FilterView(jobsView, "jobs")

    const eventsView = qs("[data-events-view]")
    if (eventsView) new FilterView(eventsView, "events")

    // article view and load logic
    const articlesViews = qsa("[data-articles-view]")
    if (articlesViews.length > 0) {
      articlesViews.forEach((av) => {
        new ArticlesView(av)
      })
    }

    const imageSliders = qsa(".media-slider__slide")
    if (imageSliders.length > 0) {
      imageSliders.forEach((e) => {
        new Gallery(e)
      })
    }

    const lbs = qsa("[data-lightbox]")
    if (lbs.length > 0) new Lightboxes(lbs)

    const phaseCircles = qsa("[data-phase-circle]")
    if (phaseCircles.length > 0) {
      new PhaseCircles(phaseCircles)
    }

    const scrollButton = qs(".scroll-to-top-button")
    if (scrollButton) new ScrollToTopButton(scrollButton)
  },
  false,
)
